import styled from '@emotion/styled';
import { Button, Radio, Tooltip } from '@mantine/core';
import { memo, useEffect, useState } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_horizontal_cross_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { capitalOrderFutures } from './orderUrl';
import { css } from '@emotion/react';
const futuresSymbolArray = ['TX-1', 'MTX-1'];
const QuickFuturesOrderBox = memo(function QuickFuturesOrderBox() {
    const [futuresSymbol, setFuturesSymbol] = useState('TX-1');
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    useEffect(() => {
        signalrStore2.addQuote(futuresSymbolArray);
        return () => {
            signalrStore2.removeQuote(futuresSymbolArray);
        };
    }, futuresSymbolArray);
    const value = useSnapshot(signalrStore2.values.quote)[futuresSymbol];
    const close = value?.close ?? 0;
    const quoteChanges = processQuoteToReadable(value);
    const change = quoteChanges?.closeChange;
    const changePercent = quoteChanges?.closeChangePercent;
    const orderSymbol = futuresSymbol === 'TX-1' ? 'FITX' : 'FIMTX';
    const orderUrl = capitalOrderFutures + orderSymbol;
    return (<styleds.container>
      <styleds.switchContent>
        <div>
          <Radio value='TX-1' label='大台指' onChange={() => setFuturesSymbol('TX-1')} checked={futuresSymbol === 'TX-1'}/>
          <Radio value='MTX-1' label='小台指' onChange={() => setFuturesSymbol('MTX-1')} checked={futuresSymbol === 'MTX-1'}/>
        </div>
        <styleds.warringContent>
          <Tooltip multiline width={324} label={warringString()} color='gray'>
            <span>下單警語</span>
          </Tooltip>
        </styleds.warringContent>
      </styleds.switchContent>
      <styleds.body>
        <styleds.symbolContent>
          <span>{dictionary[futuresSymbol]}</span>
          <span>{futuresSymbol}</span>
        </styleds.symbolContent>
        <styleds.quoteCont quote={changePercent}>
          <span>{close}</span>
          <span>
            {changePercent > 0 ? '+' : ''}
            {change}
          </span>
          <span>
            {changePercent > 0 ? '+' : ''}
            {changePercent}%
          </span>
        </styleds.quoteCont>
      </styleds.body>
      <styleds.orderButtonContent>
        <Button css={css `
            width: 100%;
          `} color='red' onClick={() => {
            window.open(orderUrl + '&FuntureBS=B', 'WindowOpen', 'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=500, height=640, top=0, left=0');
        }}>
          買進下單
        </Button>
        <Button css={css `
            width: 100%;
          `} color='green' onClick={() => {
            window.open(orderUrl + '&FuntureBS=S', 'WindowOpen', 'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=500, height=640, top=0, left=0');
        }}>
          賣出下單
        </Button>
      </styleds.orderButtonContent>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    width: 100%;
    height: 108px;
    display: grid;
    grid-template-rows: 30% 40% 30%;
  `,
    switchContent: styled.div `
    display: grid;
    grid-template-columns: 80% 20%;
    border-bottom: 1px solid #2c2e37;
    & > div {
      ${fill_horizontal_cross_center};
      width: 100%;
      gap: 16px;
    }
  `,
    warringContent: styled.div `
    ${fill_horizontal_cross_center};
    gap: 16px;
    font-size: 12px;
    padding: 0 4px;
  `,
    body: styled.div `
    ${fill_horizontal_cross_center};
    padding: 0 8px;
  `,
    symbolContent: styled.div `
    ${fill_horizontal_cross_center};
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    gap: 4px;
  `,
    quoteCont: styled.div `
    ${fill_horizontal_cross_center};
    ${jc.spaceBetween};
    color: ${props => (props.quote >= 0 ? '#ff0000' : '#00cc22')};
    font-size: 14px;
  `,
    orderButtonContent: styled.div `
    ${fill_horizontal_all_center};
    gap: 8px;
  `,
};
export default {
    Display: QuickFuturesOrderBox,
};
const warring = `
1.交易人投資前應了解商品風險，審慎評估投資能力與自身風險承受能力，並自負盈虧。
2.下單系統及輔助工具僅供參考，投資人仍需自行判斷，任何系統參數須由投資人自行設定。
3.期貨及選擇權交易具低保證金之高度財務槓桿特性，在可能產生極大利潤的同時也可能產生極大的損失，投資人於開戶前應審慎考慮本身的財務能力及經濟狀況。
4.過去的績效並不代表未來獲利，投資人仍須依據本身狀況做好審慎評估。
5.本公司所提供之即時報價資訊，不代表勸誘投資人進行期貨交易，且不保證此資料之正確性及完整性。
6.使用本公司的電子下單系統交易委託買賣，可能面臨斷線、斷電、網路壅塞等阻礙，致使委託買賣無法傳送接收或時間延遲。
`;
const warringString = () => {
    return (<div>
      {warring.split('\n').map((line, i) => (<p key={i}>{line}</p>))}
    </div>);
};
